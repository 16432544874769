<template>
  <div class="member-card">
    <div class="member-card__img-wrapper">
      <img :src="baseUrl + item.image.url" alt="Member" />
    </div>
    <div class="member-card__content">
      <div class="member-card__position">{{ item.position }}</div>
      <div class="member-card__full-name">{{ item.full_name }}</div>
      <div class="member-card__rang">{{ item.rank }}</div>
    </div>
    <div class="member-card__footer">
      <a :href="`tel:${item.phone}`">{{ item.phone }}</a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_vars';

.member-card {
  display: flex;
  flex-direction: column;
  border: 1px solid $border-color;
  &__img-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    text-align: center;
  }

  &__position {
    color: $accent;
    background: rgba(231, 192, 111, 0.4);
    padding: 4px 8px;
    border-radius: 2px;
    margin-bottom: 15px;
    font-size: 0.688rem;
    line-height: 1.2;
  }

  &__full-name {
    color: $text-dark;
    font-weight: bold;
    line-height: 1.4;
    font-size: 1.25rem;
    margin-bottom: 15px;
  }

  &__rang {
    color: $text-light;
    font-size: 0.875rem;
  }

  &__footer {
    border-top: 1px solid $border-color;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    font-weight: 500;
    a {
      color: $text;
    }
  }
}
</style>
