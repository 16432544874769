<template>
  <div class="about-page">
    <loader full :visible="isLoading" />
    <b-container class="my-3">
      <b-row v-if="!isLoading">
        <b-col cols="12" lg="8">
          <article class="single-article">
            <h2 class="single-article__title">
              {{ aboutUs[`title_${$i18n.locale}`] }}
            </h2>
            <div
              class="single-article__content"
              v-html="aboutUs[`content_${$i18n.locale}`]"
            ></div>
          </article>
          <div class="leaders mt-5">
            <h3 class="mb-4">{{ $t('leadership') }}</h3>
            <b-row>
              <b-col
                v-for="leader in leaders"
                :key="leader.id"
                cols="12"
                lg="4"
                class="mb-3"
              >
                <member-card :item="leader" />
              </b-col>
            </b-row>
          </div>
        </b-col>
        <b-col cols="12" lg="4" class="mt-4 mt-lg-0">
          <poster />
          <div class="sidebar-socials mt-5">
            <h3 class="mb-3">{{ $t('socials_title') }}</h3>
          </div>
          <social-networks />
          <div class="sidebar-infos mt-4">
            <h3 class="mb-3">{{ $t('contact_with_us') }}</h3>
            <div class="info mb-3">
              <div class="info__heading">{{ $t('phone') }}:</div>
              <a href="tel:+998712314881" class="info__content">
                +998 71 231-48-81
              </a>
            </div>
            <div class="info mb-3">
              <div class="info__heading">{{ $t('fax') }}:</div>
              <a href="tel:+998712314181" class="info__content">
                +998 71 231-41-81
              </a>
            </div>
            <div class="info mb-3">
              <div class="info__heading">{{ $t('about_subs') }}:</div>
              <a href="tel:+998712314881" class="info__content">
                +998 71 231-48-81
              </a>
            </div>
            <div class="info mb-3">
              <div class="info__heading">{{ $t('email') }}</div>
              <a href="mailto:info@postda.uz" class="info__content">
                info@postda.uz
              </a>
            </div>
            <div class="info mb-3">
              <div class="info__heading">{{ $t('address') }}:</div>
              <div class="info__content">
                {{ $t('address_full') }}
              </div>
            </div>
            <router-link
              to="feedback"
              class="button button--default button--block mt-4"
            >
              {{ $t('to_send') }}
            </router-link>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Poster from '@/components/Poster'
import SocialNetworks from '@/components/SocialNetworks'
import MemberCard from '@/components/MemberCard'
import Loader from '@/components/Loader'

export default {
  components: {
    Poster,
    SocialNetworks,
    MemberCard,
    Loader
  },
  data: () => ({
    isLoading: false
  }),
  computed: {
    aboutUs() {
      return this.$store.state.pagesModule.aboutUs
    },
    leaders() {
      return this.$store.state.pagesModule.leaders
    }
  },
  created() {
    this.isLoading = true
    Promise.all([
      this.$store.dispatch('fetchAbout'),
      this.$store.dispatch('fetchLeaders')
    ]).finally(() => {
      this.isLoading = false
    })
  },
  metaInfo() {
    return {
      title: this.$t('nav.about')
    }
  }
}
</script>
